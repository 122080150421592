<template>
<!-- 全部短剧容器 -->
<div>
  <!-- 全部短剧容器 -->
<div class="dj_container" v-if="$route.query.type==='duanju'">
<!-- 顶部导航栏 -->
<div class="nav" >
    <ul class="ul_top">
        <li class="one"><img src="../../assets/image/image1.png" alt="">&nbsp;雪顶科技</li>
        <li><a href="#">首页</a></li>
        <li><a href="#">解决方案</a></li>
        <li><a href="#">版权信息平台</a></li>
        <li><a href="#">行业动态</a></li>
        <li><a href="#">关于我们</a></li>
    </ul>
</div>
<br>
<br>
<br>
<br>
<!-- 短剧展示区域 -->
 <div class="dj_container_detail">
  <!-- 展示文本 -->
  <div class="detail_text_container">
    <span class="shouye_text"><a href="/" style="text-decoration: none;"><span class="shouye_text">首页</span></a>&nbsp;></span>&nbsp;<span class="detail_text">全部短剧</span>
  </div>
  <br>
  <!-- 展示搜索区域 -->
  <div class="search_input">
    <span class="search_icon"><img src="../../assets/image/search_icon.png" alt="搜索"></span>
    <input type="text" placeholder="请输入你要查找的内容" class="search_input_text" v-model="search_dj">
    <button class="search_input_button">搜索</button>
  </div>
  <!-- 频道和标签 -->
   <div class="channel_and_tag">
    <div class="channel_container">
        <span class="channel_text">频道：</span><br>
        <div class="channel_div">
          <ul class="channel_ul">
            <li class="channel_li"><a href="/allcontent">男频</a></li>
            <li class="channel_li"><a href="/allcontent">女频</a></li>
            <li class="channel_li"><a href="/allcontent">首发新剧</a></li>
            <li class="channel_li"><a href="/allcontent">爆款单剧</a></li>
            <li class="channel_li"><a href="/allcontent">爆款老剧</a></li>
            <li class="channel_li"><a href="/allcontent">海外版本剧</a></li>
            <li class="channel_li"><a href="/allcontent">榜单剧</a></li>
          </ul>
        </div><br>
        <span class="channel_text">标签：</span>
        <div class="channel_div">
          <ul class="channel_ul">
            <li class="channel_li"><a href="/allcontent">都市</a></li>
            <li class="channel_li"><a href="/allcontent">现代</a></li>
            <li class="channel_li"><a href="/allcontent">重生</a></li>
            <li class="channel_li"><a href="/allcontent">穿越</a></li>
            <li class="channel_li"><a href="/allcontent">玄幻</a></li>
            <li class="channel_li"><a href="/allcontent">仙侠</a></li>
            <li class="channel_li"><a href="/allcontent">古龙</a></li>
          </ul>
        </div>
    </div>
   </div>
 </div>
<!-- 更多内容 -->
<div class="related_container">
        <!-- 组合7 -->
        <div class="related_container_7">
        <div class="related_container_img">
          <ul class="short-drama-list1">
                <li class="right_li1" v-for="item in duanju_list" :key="item.id" @click="getDetailsById(item.id,type='duanju')">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <router-link to="/playlet">
                        <p class="img_bottom_text1_1">对我而言危险的他</p>
                       <p class="img_bottom_text1_2">都市传说</p>
                       </router-link>
                    </div>
                </li>
 
          </ul>
        </div>
        <br>
</div>
<div>
</div>
</div>
 <!-- 滑块区域 -->  
 <div class="float_list2">
  <div class="qiye_wechat">
    <div class="qiye_wechat_center">
      <div class="qiye_wechat_bg"></div>
      <div class="qiye_wechat_text">企业微信</div>
    </div>
  </div>
  <hr class="line">
  <div class="phone_we">
    <div class="phone_we_center">
      <div class="phone_we_bg"></div>
      <div class="phone_we_text">电话咨询</div>
    </div>
  </div>
</div>
<!-- 底部区域 -->
<div class="detail_footer_container">
        <!-- 组合7 -->
         <div class="footer_7">
         <div class="footer_6">
        <span><img src="../../assets/image/image1.png" alt="" class="two2"></span><span class="three2">雪顶科技</span>
        <br>
        <br>
        <br>
        <!-- 公司信息 -->
        <div class="company_info">
          <span>Copyright © 2024 Sohu All Rights Reserved</span><br>
          <span>客服邮箱：kf@vip.sohu.com</span><br>
          <span>增值电信业务经营许可证B1-20090148</span>
        </div>
        <!-- 服务与支持 -->
        <div class="company_service">
            <span>服务与支持</span>
            <br>
            <br>
            <ul class="service-list">
              <li><a href="">版权入驻</a></li>
              <li><a href="">常见问题</a></li>
              <li><a href="">联系我们</a></li>
              <li><a href="">短剧工具</a></li>
              <li><a href="">用户协议</a></li>
              <li><a href="">关于我们</a></li>
            </ul>
        </div>
         </div>
        <!-- 二维码 -->
        <div class="erwei_img">
          <div class="qrcode">
          </div>
          <span>微信服务</span>
         </div>
         <br>
</div>
</div>
</div>
<!-- 全部小说容器 -->
<div class="dj_container" v-else>
<!-- 顶部导航栏 -->
<div class="nav" >
    <ul class="ul_top">
        <li class="one"><img src="../../assets/image/image1.png" alt="">&nbsp;雪顶科技</li>
        <li><a href="#">首页</a></li>
        <li><a href="#">解决方案</a></li>
        <li><a href="#">版权信息平台</a></li>
        <li><a href="#">行业动态</a></li>
        <li><a href="#">关于我们</a></li>
    </ul>
</div>
<br>
<br>
<br>
<br>
<!-- 小说展示区域 -->
 <div class="dj_container_detail">
  <!-- 展示文本 -->
  <div class="detail_text_container">
    <span class="shouye_text"><a href="/" style="text-decoration: none;"><span class="shouye_text">首页</span></a>&nbsp;></span>&nbsp;<span class="detail_text">全部小说</span>
  </div>
  <br>
  <!-- 展示搜索区域 -->
  <div class="search_input">
    <span class="search_icon"><img src="../../assets/image/search_icon.png" alt="搜索"></span>
    <input type="text" placeholder="请输入你要查找的内容" class="search_input_text" v-model="search_xs">
    <button class="search_input_button">搜索</button>
  </div>
  <!-- 频道和标签 -->
   <div class="channel_and_tag">
    <div class="channel_container">
        <span class="channel_text">频道：</span><br>
        <div class="channel_div">
          <ul class="channel_ul">
            <li class="channel_li"><a href="/allcontent">男频</a></li>
            <li class="channel_li"><a href="/allcontent">女频</a></li>
            <li class="channel_li"><a href="/allcontent">首发新剧</a></li>
            <li class="channel_li"><a href="/allcontent">爆款单剧</a></li>
            <li class="channel_li"><a href="/allcontent">爆款老剧</a></li>
            <li class="channel_li"><a href="/allcontent">海外版本剧</a></li>
            <li class="channel_li"><a href="/allcontent">榜单剧</a></li>
          </ul>
        </div><br>
        <span class="channel_text">标签：</span>
        <div class="channel_div">
          <ul class="channel_ul">
            <li class="channel_li"><a href="/allcontent">都市</a></li>
            <li class="channel_li"><a href="/allcontent">现代</a></li>
            <li class="channel_li"><a href="/allcontent">重生</a></li>
            <li class="channel_li"><a href="/allcontent">穿越</a></li>
            <li class="channel_li"><a href="/allcontent">玄幻</a></li>
            <li class="channel_li"><a href="/allcontent">仙侠</a></li>
            <li class="channel_li"><a href="/allcontent">古龙</a></li>
          </ul>
        </div>
    </div>
   </div>
 </div>
<!-- 更多内容 -->
<div class="related_container">
        <!-- 组合7 -->
        <div class="related_container_7">
        <div class="related_container_img">
          <ul class="short-drama-list1">
                <li class="right_li1" v-for="item in xiaoshuo_list" :key="item.id">
                    <div class="right_div1">
                       <div class="img_div1">
                        <img src="../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png" alt="" class="short-drama-image1">
                       </div>
                       <div @click="relativeList(item.id,type='xiaoshuoo')" style="cursor: pointer;">
                          <p class="img_bottom_text1_1">{{item.name }}</p>
                          <p class="img_bottom_text1_2">{{ item.type }}</p>
                       </div>
                    </div>
                </li>
 
          </ul>
        </div>
        <br>
</div>
<div>
</div>
</div>
 <!-- 滑块区域 -->  
 <div class="float_list2">
  <div class="qiye_wechat">
    <div class="qiye_wechat_center">
      <div class="qiye_wechat_bg"></div>
      <div class="qiye_wechat_text">企业微信</div>
    </div>
  </div>
  <hr class="line">
  <div class="phone_we">
    <div class="phone_we_center">
      <div class="phone_we_bg"></div>
      <div class="phone_we_text">电话咨询</div>
    </div>
  </div>
</div>
<!-- 底部区域 -->
<div class="detail_footer_container">
        <!-- 组合7 -->
         <div class="footer_7">
         <div class="footer_6">
        <span><img src="../../assets/image/image1.png" alt="" class="two2"></span><span class="three2">雪顶科技</span>
        <br>
        <br>
        <br>
        <!-- 公司信息 -->
        <div class="company_info">
          <span>Copyright © 2024 Sohu All Rights Reserved</span><br>
          <span>客服邮箱：kf@vip.sohu.com</span><br>
          <span>增值电信业务经营许可证B1-20090148</span>
        </div>
        <!-- 服务与支持 -->
        <div class="company_service">
            <span>服务与支持</span>
            <br>
            <br>
            <ul class="service-list">
              <li><a href="">版权入驻</a></li>
              <li><a href="">常见问题</a></li>
              <li><a href="">联系我们</a></li>
              <li><a href="">短剧工具</a></li>
              <li><a href="">用户协议</a></li>
              <li><a href="">关于我们</a></li>
            </ul>
        </div>
         </div>
        <!-- 二维码 -->
        <div class="erwei_img">
          <div class="qrcode">
          </div>
          <span>微信服务</span>
         </div>
         <br>
</div>
</div>
</div>
</div>
</template>

<script>
export default {
  data(){
    return{
      search_input:'',
      // 短剧内容列表
      duanju_list:[
          {
          id:1,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:2,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'荒野行动',
          type:'都市传说',
        },
        {
          id:3,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'龙兄虎弟',
          type:'都市传说',
        },
        {
          id:4,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'飞鹰计划',
          type:'都市传说',
        },
        {
          id:5,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:6,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:7,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:8,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:9,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:10,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        }
      ],
      // 小说内容列表
      xiaoshuo_list:[
          {
          id:1,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:2,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'荒野行动',
          type:'都市传说',
        },
        {
          id:3,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'龙兄虎弟',
          type:'都市传说',
        },
        {
          id:4,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'飞鹰计划',
          type:'都市传说',
        },
        {
          id:5,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:6,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:7,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:8,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:9,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        },
        {
          id:10,
          img: require('../../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png'),
          name:'心跳原计划',
          type:'都市传说',
        }
      ],
      // 标记
      type:'',
      // 输入框
      search_dj:'',
      search_xs:''
    }
  },
  methods:{
    donghua(){
      const links = document.querySelectorAll('.nav a');
    links.forEach(link => {
      link.addEventListener('click', () => {
        link.classList.add('click-effect');
        // 移除动画类，以便下次点击时可以再次触发动画
        setTimeout(() => {
          link.classList.remove('click-effect');
        }, 300);
      });
    })
    },
    // 获取短剧列表
    getDjList(){
      if (this.$route.query.type==='duanju'){
        // 发送请求
        console.log("短剧");  
      }
      
    },
    // 获取小说列表
    getXsList(){
      if (this.$route.query.type==='xiaoshuo'){
        // 发送请求
        console.log("小说");
      }
      
    }
  },
  mounted(){
    this.donghua()
    this.getDjList()
    this.getXsList()
  }
}
</script>

<style> 
.search_input {
  position: relative;
  width: 675px;
  height: 45px;
}
.search_icon {
  position: absolute;
  left: 10px; /* 调整图标的左右位置 */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* 使图标不可点击 */

}
.search_icon img {
  width: 20px; /* 图标宽度 */
  height: 20px; /* 图标高度 */
}
.search_input_text {
  width: 100%;
  height: 45px;
  padding-left: 50px; /* 根据图标大小调整输入框的内边距 */
  box-sizing: border-box; /* 确保内边距不会影响总宽度 */
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.21);
  border: 1px solid rgb(10, 177, 255);
  color: white;
}
.search_input_button {
position: absolute;
right: 0px; /* 调整按钮的位置 */
top: 50%;
transform: translateY(-50%);
border: none;
border-radius: 4px;
cursor: pointer;
padding: 9px 40px;
box-sizing: border-box;
border-radius: 48px;
background: linear-gradient(207.60deg, rgb(0, 193, 255) 42.815%,rgb(44, 121, 255) 97.59%);
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 18px;
text-align: left;
}
.channel_and_tag{
width: 1199px;
height: 225px;
border-radius: 4px;
background: linear-gradient(180.00deg, rgba(255, 255, 255,0.2),rgba(255, 255, 255, 0) 100%);
/* border: 1px solid red; */
margin-top: 20px;
}
.channel_text{
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 18px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
text-align: left;
}
.channel_container{
width: 1199px;
height: 225px;
padding: 20px 20px;
}
.channel_div{
  display: flex;
}
.channel_ul {
  list-style-type: none; /* 移除默认的列表样式 */
  padding: 0; /* 移除默认的内边距 */
  margin: 0; /* 移除默认的外边距 */
  display: flex; /* 使用flex布局 */
  flex-wrap: wrap;
  margin-top: 10px;
}

.channel_li {
  margin-right: 70px; /* 设置每个li之间的间距 */
  border: none; /* 移除默认边框 */
  color: white; /* 设置字体颜色为白色 */
  cursor: pointer; /* 设置鼠标悬停时的光标样式 */
}
.channel_ul li {
  margin-right: 70px; /* 右侧间距 */
}
.channel_li a {
text-decoration: none; /* 移除链接的下划线 */
color: inherit; /* 继承父元素的颜色 */
color: rgb(255, 255, 255);
font-family: OPPO Sans;
font-size: 16px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
}
/* 鼠标悬浮到li时候， */
.channel_li:active a{
border-radius: 4px;
background: rgba(255, 255, 255, 0.12);
padding: 4px 8px;
color: rgb(10, 177, 255);
font-family: OPPO Sans;
font-size: 16px;
font-weight: 400;
line-height: 150%;
letter-spacing: 0px;
align-items:  center;
}
@keyframes clickAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
.click-effect {
  animation: clickAnimation 0.3s;
}
</style>