<template>
<div class="container1">
<!--头部导航栏 -->
<div class="nav" >
    <ul class="ul_top">
        <li class="one"><img src="../assets/image/image1.png" alt="">&nbsp;雪顶科技</li>
        <li><a href="#">首页</a></li>
        <li><a href="#">解决方案</a></li>
        <li><a href="#">版权信息平台</a></li>
        <li><a href="#">行业动态</a></li>
        <li><a href="#">关于我们</a></li>
    </ul>
</div>
<br>
<br>
<br>
<!-- 短剧bannner区域 -->
<div class="banner">
  <el-carousel height="600px">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
         <img :src="item.img" alt="" style="width: 100%;height: 600px;">
      </el-carousel-item> 
    </el-carousel>
</div>
<br>
<!-- 中间区域 -->
<div class="container2">
<!-- 短剧工具区域 -->
<div class="tools">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span><span class="three">短剧工具</span>
        <br>
        <br>
        <p class="four">提供大量的短剧产品和服务、为推动短剧业务提供解决方案</p>
         </div>
         <br>
         <ul class="box-list">
    <li class="box-item">
      <img src="../assets/image/model1.png" alt="图标1" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model2.png" alt="图标2" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model3.png" alt="图标3" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model4.png" alt="图标4" class="box-icon">
    </li>
    <li class="box-item">
      <img src="../assets/image/model5.png" alt="图标4" class="box-icon">
    </li>
  </ul>
</div>
</div>
<!-- 滑块区域 -->
<div class="float_list1">
  <div class="qiye_wechat">
    <div class="qiye_wechat_center">
      <div class="qiye_wechat_bg"></div>
      <div class="qiye_wechat_text">企业微信</div>
    </div>
  </div>
  <hr class="line">
  <div class="phone_we">
    <div class="phone_we_center">
      <div class="phone_we_bg"></div>
      <div class="phone_we_text">电话咨询</div>
    </div>
  </div>
</div>
<br>
<!-- 短剧版权区域 -->
<div class="banquan">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span>
        <span class="three">短剧版权内容</span>
        <br>
        <br>
        <div class="line_bg">
          <p class="four">最全的短剧作品内容，全国版权剧库，版权溯源可查</p>
          <div class="more_info more-info" @click="toMore(type='duanju')">查看更多></div>
        </div>
         </div>

        </div>
        <!--  短剧版权右边展示区域 -->
        <div style="display: flex;">
        <!--  左边导航栏 -->
         <div class="left_container">
            <!-- 展示导航栏 -->
             <ul class="left_ul">
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">首发新剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">首发新剧，重磅出击</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">爆款老剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">爆款老剧依然给力</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">优质剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">优质内容是短剧市场竞争的核心</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">备案剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">狂飙的短剧，终被套上缰绳</p>
                    </div>
                 </li>
             </ul>
         </div>
         <!-- 右侧展示短剧区域 -->
         <ul class="short-drama-list1">
              <li class="right_li1" v-for="item in duanjuList" :key="item.id">
                      <div class="right_div1">
                      <div class="img_div1">
                        <img :src="item.img" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">{{item.count}}集</span>
                       </div>
                        <div @click="getPlaylet(item.id,type='duanju')" style="cursor: pointer;">
                          <p class="img_bottom_text1_1">{{item.name }}</p>
                          <p class="img_bottom_text1_2">{{ item.type }}</p>
                        </div>
                    </div>
              </li>
         </ul>
         </div>
</div>

<!-- 小说版权区域 -->
<div class="banquan">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span>
        <span class="three">小说版权内容</span>
        <br>
        <br>
        <div class="line_bg">
          <p class="four">最全的短剧作品内容，全国版权剧库，版权溯源可查</p>
          <div class="more_info more-info" @click="toMore(type='xiaoshuo')">查看更多></div>
        </div>
         </div>

        </div>
        <div style="display: flex;">
        <!--  小说版权左边导航栏 -->
         <div class="left_container">
            <!-- 展示导航栏 -->
             <ul class="left_ul">
                 <li class="left_li">
                    <div class="hover-highlight" >
                        <span class="left_span">首发新剧</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">首发新剧，重磅出击</p>
                    </div>
                 </li>         
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">雪顶推荐</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">爆款老剧依然给力</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">最新上架</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">优质内容是短剧市场竞争的核心</p>
                    </div>
                 </li>
                 <li class="left_li">
                    <div class="hover-highlight">
                        <span class="left_span">畅销小说</span><img src="../assets/image/turn_right2.png" alt="" class="right">
                        <p class="left_p">狂飙的短剧，终被套上缰绳</p>
                    </div>
                 </li>
             </ul>
         </div>
         <!-- 右侧展示小说区域 -->
         <ul class="short-drama-list1">
              <li class="right_li1" v-for="item in xiaoshuoList" :key="item.id">
                      <div class="right_div1">
                      <div class="img_div1">
                        <img :src="item.img" alt="" class="short-drama-image1">
                        <span class="img_bottom_text1_3">{{item.count}}集</span>
                       </div>
                        <div @click="getPlaylet(item.id,type='xiaoshuo')" style="cursor: pointer;">
                          <p class="img_bottom_text1_1">{{item.name }}</p>
                          <p class="img_bottom_text1_2">{{ item.type }}</p>
                        </div>
                    </div>
              </li>
         </ul>
</div>
</div>
<!-- 行业动态区域 -->
<div class="industry_container">
        <!-- 组合7 -->
         <div class="tools_7">
         <div class="tools_6">
        <span><img src="../assets/image/title_decorate.png" alt="" class="two"></span><span class="three">行业动态</span>
        <br>
        <br>
        <div class="line_bg">
          <p class="four">聚焦行业热点，探讨新走向</p>
          <div class="more_info"><router-link to="/allcontent" class="more-info" style="text-decoration: none;">查看更多></router-link></div>
        </div>
        </div>
</div>
<br>
<!-- 新闻列表展示区域 -->
  <div class="industry_back">
    <div class="news_info">
      <ul class="news_list">
        <li class="news_info_li" v-for="item in newsList" :key="item.id">
          <span>·{{item.title}}</span>&nbsp;&nbsp;&nbsp;<button class="btn">最新</button><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <!-- <li class="news_info_li">
          <span>·广电总局要求加强对霸总微短剧管理</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·莫言强忍不看短剧，周鸿祎下场拍短剧...</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·海报时评丨整治霸总短剧，呼唤理性回归</span><span><div class="more">详情></div></span>
          <hr class="hr_line">
        </li>
        <li class="news_info_li">
          <span>·雪顶短剧重磅出新</span><span><div class="more">详情></div></span>
        </li> -->
      </ul>
    </div>
  </div>
</div>
<br>

<!-- 底部区域 -->
<div class="footer_container">
        <!-- 组合7 -->
         <div class="footer_7">
         <div class="footer_6">
        <span><img src="../assets/image/image1.png" alt="" class="two2"></span><span class="three2">雪顶科技</span>
        <br>
        <br>
        <br>
        <!-- 公司信息 -->
        <div class="company_info">
          <span>Copyright © 2024 Sohu All Rights Reserved</span><br>
          <span>客服邮箱：kf@vip.sohu.com</span><br>
          <span>增值电信业务经营许可证B1-20090148</span>
        </div>
        <!-- 服务与支持 -->
        <div class="company_service">
            <span>服务与支持</span>
            <br>
            <br>
            <ul class="service-list">
              <li><a href="">版权入驻</a></li>
              <li><a href="">常见问题</a></li>
              <li><a href="">联系我们</a></li>
              <li><a href="">短剧工具</a></li>
              <li><a href="">用户协议</a></li>
              <li><a href="">关于我们</a></li>
            </ul>
        </div>
         </div>
        <!-- 二维码 -->
        <div class="erwei_img">
          <div class="qrcode">
          </div>
          <span>微信服务</span>
         </div>
         <br>
</div>
</div>
</div>
</div>
</template>

<script>
// 引入axios
// import axios from 'axios'
export default {
    mounted() {
    this.donghua()
    // this.getAllType()
  },
  data(){
    return{
      // 轮播图数据
      bannerList:[
        {
          id:1,
          img: require('../assets/image/header_banner.png')
        },
        {
          id:2,
          img: require('../assets/image/R-C.jpg')
        },
        {
          id:3,
          img: require('../assets/image/R-C.jpg')
        },
      ],
      // 短剧版权列表
      duanjuList:[
        {
          id:1,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:2,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:3,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:4,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:5,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:6,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:7,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:8,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        }
      
      ],
      // 小说版权列表
      xiaoshuoList:[
        {
          id:1,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:2,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:3,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:4,
          name:'心跳原计划',
          type:'都市传说',
          count:20,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')

        },
        {
          id:5,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:6,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:7,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
        },
        {
          id:8,
          name:'难忘的他',
          type:'都市爱情',
          count:45,
          img: require('../assets/image/19bbc8f9a2e4cbf6eaf0b557fae68d1.png')
       },
      ],
      // 新闻列表
      newsList:[
        {
          id:1,
          title:'雪顶短剧重磅出新',
        },
        {
          id:2,
          title:'广电总局要求加强对霸总微短剧管理',
        },
        {
          id:3,
          title:'莫言强忍不看短剧，周鸿祎下场拍短剧...',
        },
        {
          id:4,
          title:'海报时评丨整治霸总短剧，呼唤理性回归',
        },
        {
          id:5,
          title:'雪顶短剧重磅出新'
        }
      ],
      // 短剧版权分类列表
      duanju_banquan_type:[],
      // 小说版权分类列表
      xiaoshuo_banquan_type:[],
      // 用于区分是短剧还是小说
      type: " "
    }
  },
  methods:{
    // 动画效果
    donghua(){
    const links = document.querySelectorAll('.nav a');
    links.forEach(link => {
      link.addEventListener('click', () => {
        link.classList.add('click-effect');
        // 移除动画类，以便下次点击时可以再次触发动画
        setTimeout(() => {
          link.classList.remove('click-effect');
        }, 300);
      });
    });
    },
    // 跳转详情页
    getPlaylet(id,type){
      // console.log(id);
      // console.log(type);
      this.$router.push({path:'/playlet',query:{id:id,type:type}});
    },
    // 跳转查看更多页
    toMore(type){
      this.$router.push({path:'/allcontent',query:{type:type}});
    }
  }
}

</script>

<style src="../assets/css/index.css">

</style>